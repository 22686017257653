import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tabber from 'components/Tabber/Tabber.react';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import UnsavedChangesModal from '../UnsavedChangesModal/UnsavedChangesModal.react';
import { switchStyleLabel } from '../ParticipantGeneralFieldInfoForm/ParticipantGeneralFieldInfoForm.style';
import {
  ADMIN,
  FORM_LOCKED,
  FORM_UNLOCKED,
  ORIGINATION_MANAGER,
} from 'utils/config';
import { checkTernaryCondition, getFarmLockedTooltip } from 'utils/helper';
import LightTooltip from 'components/LightTooltip';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import SwitchComponent from 'components/FormComponents/SwitchComponent/SwitchComponent.react';
import { FormHeading } from 'components/FormComponents/FormStyles';
import {
  HeadingWrapper,
  SwitchContainer,
} from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import { Typography } from '@mui/material';
import DeleteFarmAndFields from '../DeleteFarmAndFields/DeleteFarmAndFields.react';
import { activityInstanceStatus } from 'pages/ParticipantDataCollection/ActivityReporting/ActivityReporting.content';

const FieldInfoWithTabber = ({
  currentTab,
  setCurrentTab,
  tabList,
  handleTabChange,
  name,
  handleSwitchChange,
}) => {
  const {
    userActivityDetected,
    unsavedChangesModalOpenProps,
    setUnsavedChangesModalOpenProps,
    closeUnsavedChangesModal,
    setTriggerFieldInfoSubmit,
    setUserActivityDetected,
    farmInfo,
    currentFarmId,
    switchState,
    isSwitchDisabled,
    selectedFieldId,
    activeUser,
    setCurrentFarmId,
    setSelectedFieldId,
    setShowFieldInfoForm,
    setFarmInfoCurrentTab,
    fetchParticipantData,
    setExpandedFarmId,
    isParticipantContributor,
    currentCycleStartDate,
    currentCycleEndDate,
  } = useContext(participantInfoContext);

  const { userRoles } = useContext(userAuthRolesContext);

  // Restructured farm list for delete component usage
  const farmList = useMemo(() => {
    if (!farmInfo || farmInfo.length === 0) return [];

    return farmInfo.map((farm) => ({
      ...farm,
      farmId: farm.id,
      farmName: farm.value,
      fieldDetails: farm.fields.map((field) => ({
        ...field,
        fieldId: field.id,
        fieldName: field.value,
        farmId: farm.id,
      })),
    }));
  }, [farmInfo]);

  const switchTab = () => {
    setCurrentTab(unsavedChangesModalOpenProps.nextTab);
    closeUnsavedChangesModal();
    setUserActivityDetected(false);
  };

  const handleSaveAndLeave = () => {
    setTriggerFieldInfoSubmit(true);
  };

  const handleFieldWithMapTabChange = (tab) => {
    if (currentTab === tab) return;
    // Note: Open unsave changes modal on tab shift if there are unsaved changes, and the tab is switched from farm to map
    if (userActivityDetected && currentTab === 0) {
      setUnsavedChangesModalOpenProps({
        isOpen: true,
        nextTab: tab,
        targetFieldId: null,
        targetFieldName: '',
        targetFarmId: null,
        targetFarmName: '',
        navigateNextTo: 'Tab',
        triggeredFrom: 'Tab',
      });
      return;
    }
    setCurrentTab(tab);
  };

  const currentFarmName = farmInfo.find(
    (farm) => farm.id === currentFarmId,
  )?.value;

  const handleNavigateAfterDelete = (navigateProps) => {
    if (navigateProps.navigateTo === 'FARM') {
      setSelectedFieldId(null);
      setCurrentFarmId(navigateProps.farmData.farmId);
      setExpandedFarmId(navigateProps.farmData.farmId);
      setShowFieldInfoForm(false);
      setFarmInfoCurrentTab(0);
    } else if (navigateProps.navigateTo === 'FIELD') {
      setSelectedFieldId({
        id: navigateProps.fieldData.fieldId,
        name: navigateProps.fieldData.fieldName,
      });
    }

    fetchParticipantData(false);
  };

  const selectedFarm = farmInfo?.find((farm) => farm.id === currentFarmId);

  const isFarmApproved =
    selectedFarm?.activityReportingStatus === activityInstanceStatus.approved;

  const selectedField = selectedFarm?.fields?.find(
    (field) => field.id === selectedFieldId?.id,
  );

  const isFieldApproved =
    selectedField?.activityReportingStatus === activityInstanceStatus.approved;

  return (
    <>
      <HeadingWrapper>
        <FormHeading>{`${currentFarmName ?? ''}-${name}`}</FormHeading>
        <SwitchContainer>
          <Typography sx={switchStyleLabel}>
            {checkTernaryCondition(!switchState, FORM_LOCKED, FORM_UNLOCKED)}
          </Typography>
          <LightTooltip
            title={getFarmLockedTooltip(
              isSwitchDisabled,
              userRoles?.includes(ADMIN) ||
                userRoles?.includes(ORIGINATION_MANAGER),
              activeUser.name,
            )}
            arrow>
            <div>
              <SwitchComponent
                handleChange={handleSwitchChange}
                name="field-lock"
                isChecked={switchState}
                stylesSx={{ marginTop: '0.3rem' }}
                disabled={isSwitchDisabled}
              />
            </div>
          </LightTooltip>
        </SwitchContainer>
        {switchState && !isFieldApproved && (
          <DeleteFarmAndFields
            farmList={farmList}
            currentFarmId={currentFarmId}
            currentFieldId={selectedFieldId?.id}
            projectCycleData={{
              cycleStartDate: currentCycleStartDate,
              cycleEndDate: currentCycleEndDate,
            }}
            refreshData={() => fetchParticipantData(false)}
            navigateCallback={handleNavigateAfterDelete}
            hideFarmDelete={isParticipantContributor || isFarmApproved}
            isParticipantContributor={isParticipantContributor}
          />
        )}
      </HeadingWrapper>
      <Tabber
        currentTab={currentTab}
        setCurrentTab={handleFieldWithMapTabChange}
        tabList={tabList}
        onTabChange={handleTabChange}
      />

      <UnsavedChangesModal
        open={
          unsavedChangesModalOpenProps?.isOpen &&
          unsavedChangesModalOpenProps?.triggeredFrom === 'Tab'
        }
        handleClose={closeUnsavedChangesModal}
        handleLeaveWithoutSave={switchTab}
        handleSaveAndLeave={handleSaveAndLeave}
      />
    </>
  );
};

FieldInfoWithTabber.propTypes = {
  currentTab: PropTypes.number,
  setCurrentTab: PropTypes.func,
  tabList: PropTypes.array,
  handleTabChange: PropTypes.func,
  name: PropTypes.string,
  handleSwitchChange: PropTypes.func,
};

export default FieldInfoWithTabber;
