export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_PAGINATION_OPTIONS = [25, 50, 75];
export const LABEL_ROWS_PER_PAGES = 'Items per page';
export const PAGINATION_MODE = 'server';
export const LOADING = 'LOADING';
export const UPLOADING = 'UPLOADING';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';
export const NOT_UPLOADED = 'NOT_UPLOADED';
export const RUN_CALS = 'RUN_CALCULATION';
export const SUCCESS = 'SUCCESS';
export const CALS_FAILED = 'CALCULATION_FAILED';
export const RUNNING = 'RUNNING_CALCULATIONS';
export const DEFAULT_CLIENT_ID = 100;
export const COMMITMENTS = 'COMMITMENTS';
export const CONTRACT = 'CONTRACT';
export const CONTRACT_COST_SCHEDULE = 'CONTRACT_COST_SCHEDULE';
export const CREDIT_BATCHES = 'CREDIT_BATCHES';
export const CREDIT_DEMAND_SCHEDULE = 'CREDIT_DEMAND_SCHEDULE';
export const CREDIT_REQUESTS = 'CREDIT_REQUESTS';
export const CREDIT_SUPPLY_SCHEDULE = 'CREDIT_SUPPLY_SCHEDULE';
export const PRICE_ASSUMPTIONS = 'PRICE_ASSUMPTIONS';
export const PROJECTS = 'PROJECTS';
export const STAKEHOLDERS = 'STAKEHOLDERS';
export const SUPPLY_CONTRACT_PROJECT_MAPPING =
  'SUPPLY_CONTRACT_PROJECT_MAPPING';
export const UNIT_COST_SCHEDULE = 'UNIT_COST_SCHEDULE';
export const EMPTY_STRING = '--';
export const CLIENT_ID = 100;
export const CHANGE_LOG_PAGESIZE = 20;
export const MAX_CHAR_LIMIT = 3000;
export const DATE_FORMAT = 'MM/DD/YYYY';
export const YEAR_FORMAT = 'YYYY';
/** Page titles */
export const PAGENAME = {
  STAKEHOLDERS_LIST: 'Stakeholders',
};

/* constants for colors */
export const GRAY_TWENTY = '#333333';
export const LIGHT_GREEN = '#86BC25';
export const DARK_RED = '#DA291C';
export const DEEP_YELLOW = '#FFCD00';
export const GREEN = '#26890D';

/** Looker Dashboard */
export const OVERVIEW_DASHBOARD_ID = 'aspiration::portfolio_overview';
export const OVERVIEW_DASHBOARD_TABLE_ID =
  'aspiration::portfolio_totals_by_status_and_developer';
export const STAKEHOLDER_PROFILE_ID = 'aspiration::stakeholder_profile';
export const PROTFOLIO_SCHEDULE_ID =
  'aspiration::portfolio_schedule_by_credit_supply';
export const PROJECT_OVERVIEW_ID = 'aspiration::projects_overview';
export const PROJECT_PROFILE_ID_MAIN = 'aspiration::project_profile_main';
export const PROJECT_PROFILE_ID_MAP = 'aspiration::project_profile_map';
export const RIGHT_TEXT_ALIGN = 'right';

/* Download File Names */
export const filename = {
  COMMITMENTS: 'commitments',
  CONTRACT: 'contracts',
  CONTRACT_COST_SCHEDULE: 'contract-cost-schedules',
  CREDIT_BATCHES: 'credit-batches',
  CREDIT_DEMAND_SCHEDULE: 'credit-demand-schedules',
  CREDIT_REQUESTS: 'credit-requests',
  CREDIT_SUPPLY_SCHEDULE: 'credit-supply-schedule',
  PRICE_ASSUMPTIONS: 'price-assumptions',
  PROJECTS: 'projects',
  STAKEHOLDERS: 'stakeholders',
  SUPPLY_CONTRACT_PROJECT_MAPPING: 'supply-contract-project-mapping',
  UNIT_COST_SCHEDULE: 'unit-cost-schedule',
};

/*Enabling replace options as per area code from api for file upload*/
export const ENABLE_OPTIONS = ['PRICE_ASSUMPTIONS'];

export const PORTFOLIO = '/assets/portfolio';

/*ASSETS And ORIGINATION tab names*/
export const ASSETS = 'assets';
export const ORIGINATION = 'origination';
export const ADD_ORIGINATION_PROJECT = 'add-origination-project';
export const VIEW_ORIGINATION_PROJECT = 'view-origination-project';
export const EDIT_ORIGINATION_PROJECT = 'edit-origination-project';
export const GENERATE_ORIGINATION_PDF_REPORT = 'generate-report';
/*Assets path names*/
export const PORTFOLIOURL = 'portfolio';
export const ADDPROJECT = 'add-project';
export const EDITPROJECT = 'edit-project';
export const ADDSTAKEHOLDER = 'add-stakeholders';
export const PROJECTDETAILS = 'project-details';
export const EDITSTAKEHOLDER = 'edit-stakeholder';
export const STAKEHOLDERPROFILE = 'stakeholder-profile';
export const ADDPURCHASE = 'add-purchase-transaction';
export const EDITPURCHASE = 'edit-purchase';
export const VIEWPURCHASE = 'view-purchase-transaction';
export const ADDSALE = 'add-sale-transaction';
export const EDITSALE = 'edit-sale';
export const VIEWSALE = 'view-sale-transaction';
export const AUDITLOG = 'audit-log';
export const DATALIBRARY = 'data-library';

/*User permissions */
export const ASSET_STAKEHOLDER_WRITE = 'Asset_Stakeholder_WRITE';
export const ASSET_STAKEHOLDER_READ = 'Asset_Stakeholder_READ';
export const ASSET_TRANSACTION_WRITE = 'Asset_Transaction_WRITE';
export const ASSET_TRANSACTION_READ = 'Asset_Transaction_READ';
export const ASSET_PROJECTS_WRITE = 'Asset_Projects_WRITE';
export const ASSET_PROJECTS_READ = 'Asset_Projects_READ';
export const ORIGINATION_PARTICIPANT_READ = 'Origination Participant_READ';
export const ORIGINATION_PARTICIPANT_CREATE = 'Origination Participant_CREATE';
export const ORIGINATION_PARTICIPANT_UPDATE = 'Origination Participant_UPDATE';
export const ORIGINATION_PARTICIPANT_DELETE = 'Origination Participant_DELETE';
export const ORIGINATION_PROJECT_READ = 'Origination Project_READ';
export const ORIGINATION_PROJECT_UPDATE = 'Origination Project_UPDATE';
export const ORIGINATION_PROJECT_DELETE = 'Origination Project_DELETE';
export const ORIGINATION_PROJECT_CREATE = 'Origination Project_CREATE';
export const ORIGINATION_PROJECT_CALCULATIONS_READ =
  'Project Calculations_READ';
export const ORIGINATION_PROJECT_CALCULATIONS_CREATE =
  'Project Calculations_CREATE';
export const ORIGINATION_PROJECT_ENROLL_PARTICIPANT_ON_BEHALF =
  'Enroll Participant On Behalf_CREATE';
export const ORIGINATION_PROJECT_ENTER_BASELINE_ON_BEHALF =
  'Baseline On Behalf_CREATE';
export const ORIGINATION_PROJECT_SUMMARY_REPORT_CREATE =
  'Project Summary Report_CREATE';
export const ORIGINATION_PROJECT_ENROLLMENT_REPORT_CREATE =
  'Project Enrollment Excel Report_CREATE';
export const ORIGINATION_PROJECT_BASELINE_REPORT_CREATE =
  'Project Baseline Excel Report_CREATE';
export const ORIGINATION_PROJECT_ACTIVITY_REPORT_CREATE =
  'Project Activity Excel Report_CREATE';
export const USER_MANAGEMENT_CREATE = 'User Management_CREATE';
export const USER_MANAGEMENT_READ = 'User Management_READ';
export const USER_MANAGEMENT_UPDATE = 'User Management_UPDATE';
export const USER_MANAGEMENT_DELETE = 'User Management_DELETE';
export const ORIGINATION_EMAIL_CREATE = 'Origination Email_CREATE';

/*User roles */
export const ORIGINATION_VIEWER = 'VIEWER';
export const ADMIN = 'ADMIN';
export const ORIGINATION_MANAGER = 'ORIGINATION_MANAGER';
export const PARTICIPANT = 'PARTICIPANT';
export const PARTICIPANT_MANAGER = 'PARTICIPANT_MANAGER';
export const PARTICIPANT_CONTRIBUTOR = 'PARTICIPANT_CONTRIBUTOR';

/* Client config */
export const CLIENT_SUPPORT_EMAIL_KEY = 'support-email';
export const CLIENT_INFORMATION_EMAIL_KEY = 'client_support_email';
export const CLIENT_LOGO_URL_KEY = 'Client-logo';
export const CLIENT_LOGO_BASE64_KEY = 'Client-logo-base64';
export const CLIENT_TYSON_EMAIL = 'tyson_email';

export const stateOptions = [
  { value: 'Alabama', label: 'AL' },
  { value: 'Alaska', label: 'AK' },
  { value: 'Arizona', label: 'AZ' },
  { value: 'Arkansas', label: 'AK' },
  { value: 'California', label: 'CA' },
  { value: 'Colorado', label: 'CO' },
  { value: 'Connecticut', label: 'CT' },
  { value: 'Delaware', label: 'DE' },
  { value: 'Florida', label: 'FL' },
  { value: 'Georgia', label: 'GA' },
  { value: 'Hawaii', label: 'HI' },
  { value: 'Idaho', label: 'ID' },
  { value: 'Illinois', label: 'IL' },
  { value: 'Indiana', label: 'IN' },
  { value: 'Iowa', label: 'IA' },
  { value: 'Kansas', label: 'KS' },
  { value: 'Kentucky', label: 'KY' },
  { value: 'Louisiana', label: 'LA' },
  { value: 'Maine', label: 'ME' },
  { value: 'Maryland', label: 'MD' },
  { value: 'Massachusetts', label: 'MA' },
  { value: 'Michigan', label: 'MI' },
  { value: 'Minnesota', label: 'MN' },
  { value: 'Mississippi', label: 'MS' },
  { value: 'Missouri', label: 'MO' },
  { value: 'Montana', label: 'MT' },
  { value: 'Nebraska', label: 'NE' },
  { value: 'Nevada', label: 'NV' },
  { value: 'New Hampshire', label: 'NH' },
  { value: 'New Jersey', label: 'NJ' },
  { value: 'New Mexico', label: 'NM' },
  { value: 'New York', label: 'NY' },
  { value: 'North Carolina', label: 'NC' },
  { value: 'North Dakota', label: 'ND' },
  { value: 'Ohio', label: 'OH' },
  { value: 'Oklahoma', label: 'OK' },
  { value: 'Oregon', label: 'OR' },
  { value: 'Nebraska', label: 'NE' },
  { value: 'Pennsylvania', label: 'PA' },
  { value: 'Rhode Island', label: 'RI' },
  { value: 'South Carolina', label: 'SC' },
  { value: 'South Dakota', label: 'SD' },
  { value: 'Tennessee', label: 'TN' },
  { value: 'Texas', label: 'TX' },
  { value: 'Utah', label: 'UT' },
  { value: 'Vermont', label: 'VT' },
  { value: 'Virginia', label: 'VA' },
  { value: 'Washington', label: 'WA' },
  { value: 'West Virginia', label: 'WV' },
  { value: 'Wisconsin', label: 'WI' },
  { value: 'Wyoming', label: 'WY' },
];

export const GLOBAL_STRING_CONSTANTS = {
  empty_string: '_',
  default_farm_name_prefix: 'Farm ',
  default_field_name_prefix: 'Field ',
  OPTIONAL_TEXT: '(Optional)', // for global optional text
  OPTIONAL_TEXT_WITHOUT_BRACKETS: 'Optional', // for global optional text
};
//Boolean constants
export const TRUE = true;
export const FALSE = false;
export const mapLibraries = ['places', 'drawing', 'geometry'];

export const MODULE_KEY_VALUES = [
  'carbon_asset_origination',
  'inset_management',
  'offset_management',
];

export const MODULE_KEY_NAME_MAP = {
  carbon_asset_origination: 'Carbon asset origination',
  inset_management: 'Inset management',
  offset_management: 'Offset management',
};

export const ModulesEnableMapper = {
  PORTFOLIO_INSETTING: ['Inset management'],
  PORTFOLIO_OFFSETTING: ['Offset management'],
  PORTFOLIO_ORIGINATION: ['Carbon asset origination'],
  PORTFOLIO: [
    'Inset management',
    'Offset management',
    'Carbon asset origination',
  ],
  PROJECTS: [
    'Inset management',
    'Offset management',
    'Carbon asset origination',
  ],
  STAKEHOLDERS: [
    'Inset management',
    'Offset management',
    'Carbon asset origination',
  ],
  TRANSACTIONS: ['Inset management', 'Offset management'],
  AUDIT_LOG: [
    'Inset management',
    'Offset management',
    'Carbon asset origination',
  ],
  SETTINGS: [
    'Inset management',
    'Offset management',
    'Carbon asset origination',
  ],
  REPORTS: [
    'Inset management',
    'Offset management',
    'Carbon asset origination',
  ],
};

export const getIsModuleEnabled = (moduleMapList, modulesListData) => {
  let isModuleEnabled = false;
  moduleMapList?.forEach((module) => {
    let enabledModules = modulesListData?.filter(
      (data) => data.value === 'yes' && data.name === module,
    );
    if (enabledModules?.length > 0) {
      isModuleEnabled = true;
    }
  });
  return isModuleEnabled;
};

export const getEnvironmentEnabledFeatures = (list, prefix) => {
  const trueSuffix = '_t';
  const falseSuffix = '_f';

  for (let i = 0; i < list?.length; i++) {
    const item = list[i];
    if (item.startsWith(prefix)) {
      if (item.endsWith(trueSuffix)) {
        return true;
      } else if (item.endsWith(falseSuffix)) {
        return false;
      }
    }
  }
  return null; // Return null if no matching item is found
};

export const PROJECTED = 'Projected';
export const ACTUAL = 'Actual';
export const ACCOUNT_STATUS_ACTIVATED = 'ACTIVATED';

export const REPORTING_STATUS = {
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  NOT_STARTED: 'Not Started',
  IN_PROGRESS_LOWERCASE: 'in progress',
};
export const contributorAccessRoutes = [
  'stakeholders/participant-data-collection',
  'stakeholders/participant-field-baseline',
  'stakeholders/enrollment-landing-page',
  'stakeholders/enrollment-menu-page',
  'stakeholders/baseline-landing-page',
  'stakeholders/baseline-menu-page',
  'stakeholders/activity-landing-page',
  'stakeholders/activity-reporting-menu-page',
  'stakeholders/contributor-landing-page',
  'stakeholders/contributor-reporting-menu-page',
  'stakeholders/participant-field-activity',
  'stakeholders/activity-reporting-menu-page',
  'stakeholders/landing-page',
];

// for tooltip placement for MUI tooltip
export const TOOLTIP_PLACEMENTS = {
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM: 'bottom',
  LEFT_END: 'left-end',
  LEFT_START: 'left-start',
  LEFT: 'left',
  RIGHT_END: 'right-end',
  RIGHT_START: 'right-start',
  RIGHT: 'right',
  TOP_END: 'top-end',
  TOP_START: 'top-start',
  TOP: 'top',
};

export const INACTIVE_EMAIL_TEMLATE_TEXT =
  'Email template is in inactive state';

export const USER_STATUS = {
  DISPLAY_VALUES: {
    NA: 'N/A',
    PENDING_ACTIVATION: 'Pending activation',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
  },
  STATUS: {
    NA: 'NA',
    PENDING_ACTIVATION: 'PENDING_ACTIVATION',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  },
};

export const ACTIVITY_CATEGORY = {
  COVER_CROP: 'Cover crop',
  SOIL_CARBON_AMENDMENT: 'Soil carbon amendment',
  RESIDUE_PLUS_TILLAGE: 'Residue + tillage management',
  EDGE_OF_FIELD: 'Edge of field',
  NUTRIENT_MANAGEMENT: 'Nutrient management',
};

export const INPUT_CATEGORY = {
  TEXT: 'text-field',
  NUMBER: 'number-field',
  EMAIL: 'email-field',
  DATE: 'date-field',
  PHONE: 'phone-field',
  NAME_FIELD: 'name-field',
  LONG_NUMBER: 'long-numbers-field',
  PERCENT: 'percent-field',
};

export const COMPONENT_TYPES = {
  LOCALITY: 'locality',
  ADMINISTRATIVE_AREA_LEVEL_1: 'administrative_area_level_1',
  ADMINISTRATIVE_AREA_LEVEL_2: 'administrative_area_level_2',
  POSTAL_CODE: 'postal_code',
  COUNTRY: 'country',
  ROUTE: 'route',
};
export const ToastType = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const ACTIVITY_TYPES = {
  participant: 'Participant',
  baseline: 'Baseline',
  activity: 'Activity',
};

export const COMPLETED_STATUS_LIST = [
  'ENROLL-SUBMIT',
  'ENROLL-REVIEW',
  'ENROLL-APPROVE',
  'BASELINE-SUBMIT',
  'BASELINE-REVIEW',
  'BASELINE-APPROVE',
  'ACTIVITY-SUBMIT',
  'ACTIVITY-REVIEW',
  'ACTIVITY-APPROVE',
];

export const USER_EVENTS = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

export const FARM_LOCKED_TOOLTIP_MESSAGE =
  'This farm has been locked for editing by';

export const FORM_UNLOCKED = 'Form unlocked';
export const FORM_LOCKED = 'Form locked';
export const ERROR = 'error';
export const INVENTORY_ACCOUNTING = 'Inventory accounting';

export const ERROR_MSGS = {
  FETCH: 'Failed to fetch the data. Please try again.',
  EDIT: 'Failed to edit the data. Please try again.',
  UPDATE: 'Failed to update the data. Please try again.',
  SAVE: 'Failed to save your changes. Please try again.',
  SUBMIT: 'Failed to submit your changes. Please try again.',
  DELETE: 'Failed to delete. Please try again.',
  RETRIEVE: 'Failed to retrieve the data. Please try again.',
  DOWNLOAD: 'Failed to download the file. Please try again.',
  UNEXPECTED: 'An unexpected error occurred. Please try again.',
};

export const FARM_LOCK_MODALS_DATA = {
  LOCKING_SOON_MODAL: {
    HEADING: 'Form locking soon',
    BODY: 'The form will be locked due to inactivity. Extend the session to avoid losing unsaved work.',
  },
  FORM_LOCKED_INACTIVITY: {
    HEADING: 'Form locked due to inactivity',
    BODY: "Any changes made after your most recent save have been discarded. If you're unable to unlock the form, it may be currently locked by another user. Please try again later or contact support for assistance.",
  },
  LOCKED_BY_ANOTHER_USER: {
    HEADING: 'Form locked by another user',
  },
  BUTTONS: {
    CLOSE: 'Close',
    EXTEND_SESSION: 'Extend session',
  },
  MODAL_TYPES: {
    LOCKING_SOON_MODAL: 'LOCKING_SOON_MODAL',
    FORM_LOCKED_INACTIVITY: 'FORM_LOCKED_INACTIVITY',
    LOCKED_BY_ANOTHER_USER: 'LOCKED_BY_ANOTHER_USER',
  },
};

// common graph constants
export const ADD_PROJECTIONS = 'Add projections';
export const EDIT_PROJECTIONS = 'Edit projections';
export const NO_PROJECTIONS_ADDED_TEXT = 'Add projections to view data.';

export const IDLE_TIMER = {
  IDLE_TIME_OUT: 1000 * 60 * 30, //30 minutes
  WARNING_TIME_OUT: 1000 * 60 * 2, //2 minute
};
